import profile_pic from "./images/IMG_5528.png"

function App() {
  return (
    <div>
      <div className="p-5 bg-dark text-white text-center">
        <h1 className="display-2"><strong>Niranj Sasikumar</strong></h1>
        <p className=" display-6 fs-3 mt-3">Computer Science Graduate Student & Software Developer</p>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-md-3">
            <img src={profile_pic} alt="Niranj Sasikumar" className="rounded-circle px-3 pb-4" style={{width: "100%", height: "auto"}} />
          </div>
          <div className="col-md-9">
            <p>
              Hi, I'm Niranj!
              I'm a graduate student currently pursuing a Master of Science in Computer Science from the University of Colorado,
              while also working as a Software Developer at DataAnnotation.
              My interests include machine learning, artificial intelligence, data science, and software development.
              I have 1+ years of experience in software development.
            </p>

            <h2 className="h4 mt-4 mb-3">Education</h2>
            <p>
              <strong>Master of Science, Computer Science</strong><br />
              University of Colorado<br />
              Dec 2025 (Expected)
            </p>
            <p>
              <strong>Bachelor of Science, Machine Learning and Data Science</strong><br />
              University of Toronto<br />
              Apr 2023
            </p>

            <h2 className="h4 mt-4 mb-3">Experience</h2>
            <p>
              <strong>Software Developer</strong><br />
              DataAnnotation<br />
              Remote<br />
              Jan 2024 - Present
            </p>
            <p>
              <strong>Web Developer (Co-op)</strong><br />
              Ontario Treasury Board Secretariat<br />
              Toronto, ON<br />
              May 2022 - Aug 2022
            </p>
            <p>
              <strong>Front-End Developer (Co-op)</strong><br />
              Ontario Ministry of Children, Community and Social Services<br />
              Toronto, ON<br />
              Jan 2020 - Sep 2020
            </p>
          </div>
          <div className="text-center pb-3">
            <a href="https://www.linkedin.com/in/niranjsasikumar/" className="btn btn-primary btn-lg mx-2 mt-2" target="_blank" rel="noreferrer">LinkedIn</a>
            <a href="https://github.com/niranjsasikumar" className="btn btn-primary btn-lg mx-2 mt-2" target="_blank" rel="noreferrer">GitHub</a>
          </div>
        </div>

        <hr className="my-5" />

        <h2 className="text-center">Personal Projects</h2>

        <div className="border border-2 border-secondary shadow p-3 my-4">
          <h2 className="h4">Hockey Data Hub</h2>
          <div className="mt-3 mb-2">
            <span className="badge bg-secondary me-1">JavaScript</span>
            <span className="badge bg-secondary me-1">React</span>
            <span className="badge bg-secondary me-1">CSS</span>
            <span className="badge bg-secondary me-1">Node.js</span>
            <span className="badge bg-secondary me-1">Express</span>
            <span className="badge bg-secondary me-1">MySQL</span>
          </div>
          <p>Web application that displays stats and information related to the National Hockey League (NHL) using the NHL API. Comprises of a React front-end, an Express RESTful API, and a MySQL database.</p>
          <a href="https://hockeydatahub.niranjsasikumar.com/" className="btn btn-primary mb-2 me-1" target="_blank" rel="noreferrer">View web application</a>
          <a href="https://github.com/niranjsasikumar/Hockey-Data-Hub" className="btn btn-primary mb-2" target="_blank" rel="noreferrer">View source code on GitHub</a>
        </div>

        <div className="border border-2 border-secondary shadow p-3 mt-4">
          <h2 className="h4">Simple Unit Converter</h2>
          <div className="mt-3 mb-2">
            <span className="badge bg-secondary me-1">Kotlin</span>
            <span className="badge bg-secondary me-1">XML</span>
          </div>
          <p>Android application published on the Google Play Store that performs unit conversions across various categories such as area, digital storage, length, mass, speed, temperature, time, and volume.</p>
          <a href="https://play.google.com/store/apps/details?id=com.niranjsasikumar.simpleunitconverter" className="btn btn-primary mb-2 me-1" target="_blank" rel="noreferrer">View on Google Play</a>
          <a href="https://github.com/niranjsasikumar/Simple-Unit-Converter" className="btn btn-primary mb-2" target="_blank" rel="noreferrer">View source code on GitHub</a>
        </div>

        <div className="border border-2 border-secondary shadow p-3 my-4">
          <h2 className="h4">Ray Tracing-Based 3D Renderer</h2>
          <div className="mt-3 mb-2">
            <span className="badge bg-secondary">C++</span>
          </div>
          <p>3D renderer developed using C++ that uses ray tracing to simulate realistic lighting, shadows, and reflections and generates images of three-dimensional objects and scenes.</p>
          <a href="https://github.com/niranjsasikumar/3D-Renderer" className="btn btn-primary mb-2" target="_blank" rel="noreferrer">View source code on GitHub</a>
        </div>

        <div className="border border-2 border-secondary shadow p-3 my-4">
          <h2 className="h4">Image Classifier Package</h2>
          <div className="mt-3 mb-2">
            <span className="badge bg-secondary me-1">Python</span>
            <span className="badge bg-secondary">PyTorch</span>
          </div>
          <p>A Python package for image classification. Built on the PyTorch framework, the imageclassifier package simplifies the process of creating and training an image classifier and making predictions using the classifier.</p>
          <a href="https://github.com/niranjsasikumar/Image-Classifier" className="btn btn-primary mb-2" target="_blank" rel="noreferrer">View source code on GitHub</a>
        </div>

        <div className="border border-2 border-secondary shadow p-3 my-4">
          <h2 className="h4">Matrix Calculator</h2>
          <div className="mt-3 mb-2">
            <span className="badge bg-secondary">Java</span>
          </div>
          <p>Java program capable of performing various matrix operations such as addition, matrix multiplication, inversion, etc. The calculator also allows users to create and save matrices so that they may be used later.</p>
          <a href="https://replit.com/@niranjsasikumar/MatrixCalculator" className="btn btn-primary mb-2 me-1" target="_blank" rel="noreferrer">View and run on Replit</a>
          <a href="https://github.com/niranjsasikumar/Matrix-Calculator" className="btn btn-primary mb-2" target="_blank" rel="noreferrer">View source code on GitHub</a>
        </div>

        <div className="border border-2 border-secondary shadow p-3 my-4">
          <h2 className="h4">Days Calculator</h2>
          <div className="mt-3 mb-2">
            <span className="badge bg-secondary me-1">JavaScript</span>
            <span className="badge bg-secondary me-1">AngularJS</span>
            <span className="badge bg-secondary me-1">HTML</span>
            <span className="badge bg-secondary me-1">CSS</span>
            <span className="badge bg-secondary me-1">Bootstrap</span>
          </div>
          <p>Web utility that calculates the number of days from today until a specified date, the number of days that have passed since a specified date, and the number of days between two dates.</p>
          <a href="https://dayscalculator.niranjsasikumar.repl.co" className="btn btn-primary mb-2 me-1" target="_blank" rel="noreferrer">View website</a>
          <a href="https://github.com/niranjsasikumar/Days-Calculator" className="btn btn-primary mb-2" target="_blank" rel="noreferrer">View source code on GitHub</a>
        </div>
      </div>
    </div>
  );
}

export default App;
